import { ComponentPropsWithoutRef } from 'react';

import classNames from 'classnames';

import ImageProxy from '@next-image-proxy';

import { eye3 } from '@shared/assets/images/scope/errorPage';

import { ReactComponent as UpcomingIcon } from '@launchpad/assets/icons/upcoming-sales.svg';

interface EmptyWidgetProps extends ComponentPropsWithoutRef<'div'> {
  view?: 'eye' | 'document';
  title?: string;
  description?: string;
}

const EmptyWidget = ({
  view = 'eye',
  title = 'Nothing here yet',
  description = 'Currently Empty',
  className,
  ...props
}: EmptyWidgetProps) => {
  if (view === 'document') {
    return (
      <div
        className={classNames(
          'flex flex-col justify-center items-center gap-3 p-2 sm:p-4 bg-primary-50 border border-neutral-100 rounded',
          className,
        )}
        {...props}
      >
        <UpcomingIcon className="w-[130px] mb-3" />
        <p className="mb-6 font-semibold">{description}</p>
      </div>
    );
  }

  if (view === 'eye') {
    return (
      <div
        className={classNames(
          'flex flex-col text-center justify-center items-center py-10',
          className,
        )}
        {...props}
      >
        <ImageProxy src={eye3} alt="" className="w-[88px] object-cover" />
        <p className="font-semibold">{title}</p>
        <p className="text-sm text-neutral-400 pb-3">{description}</p>
      </div>
    );
  }

  return null;
};

export default EmptyWidget;
